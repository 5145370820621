import * as React from "react"
import { Link } from 'gatsby'
import '../styles/notfound.scss'
const ErrorPage = () => {
  return (
    <div className="notfound-container">
      <div className="notfound-card">
        <div className="title">Sorry... 404!</div>
        <p>
          The page you were looking for appears to have been moved, deleted or
          does not exist. You could go back to where you were or head straight
          to our{' '}
          <Link to="/">
            home page
          </Link>.
        </p>
      </div>
    
    </div>
  )
}
export default ErrorPage
